import React, { useState, useEffect, useRef } from 'react';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import styled from 'styled-components';

const Container = styled.div`
  margin: 40px auto;
  max-width: 1210px;

  text-align: center;
  background-color: #182329;
  color: #fff;
  border-radius: 15px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
  border: 1px solid #555;

  @media (max-width: 768px) {
    padding: 15px;
  }
`;

const ContainerTexto = styled.div`
  text-align: left;
  color: #f5f5f5;
  margin: 20px 0;
`;

const NSAPlusTitulo = styled.h2`
 font-family: 'GarnetLight';

  font-size: 25px; 
  color: #a0f000;
  text-align: center;
  padding-bottom: 10px;
  padding-top: 20px !important;
`;

const PdfViewerStyled = styled.div`

  height: 800px; // Altura fija para el visor de PDF
  overflow-y: auto; // Desplazamiento si el contenido es muy largo
  display: flex; // Flexbox para centrar el contenido
  justify-content: center; // Centrar contenido horizontalmente
  z-index: 100;
  max-width: 100%; // Asegura que no se salga del contenedor
  
  .pdf-viewer {
 
    width: 100%; // Ancho completo dentro del contenedor
  }

  .react-pdf__Page {
    margin:  auto; // Margen automático para centrar cada página
  }
`;

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Documentacion = () => {
  const [numPages, setNumPages] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(1);
  const containerRef = useRef(); // Referencia al contenedor

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    adjustZoom();
  }

  // Ajustar el zoom en base al ancho del contenedor
  const adjustZoom = () => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.offsetWidth;
      const maxScale = 2; // Un límite máximo para el zoom
      let scale = containerWidth / 600;
      scale = Math.min(scale, maxScale); // Limitar el zoom máximo
      setZoomLevel(scale);
    }
  };

  // Escuchar cambios de tamaño y ajustar el zoom
  useEffect(() => {
    window.addEventListener('resize', adjustZoom);
    return () => window.removeEventListener('resize', adjustZoom);
  }, []);

  
  return (
    <div ref={containerRef}>
      <Container>
        <ContainerTexto>
          <NSAPlusTitulo>Juegos de Pie</NSAPlusTitulo>
        </ContainerTexto>

        <PdfViewerStyled>
          <Document
            file="/PDF/juegodepies.pdf"
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} scale={zoomLevel} />
            ))}
          </Document>
        </PdfViewerStyled>
      </Container>
    </div>
  );
};

export default Documentacion;