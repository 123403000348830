import React from 'react'
import ReactPlayer from 'react-player'
import './videostyles.css'

const videos = [
  {
    id: 1,
    title: 'Técnica de Peloteo Avanzada',
    filename: 'peloteo.mp4',
    description:
      'Mejora tu técnica de peloteo con este video que te proporciona consejos y ejercicios prácticos para perfeccionar tu juego en la cancha.',
  },
  {
    id: 2,
    title: 'Optimización de la Altura del Saque',
    filename: 'altura.mp4',
    description:
      'Aprende las mejores técnicas para optimizar la altura de tu saque y sorprender a tus oponentes. Este video te guiará a través de consejos clave y ejercicios específicos.',
  },
  {
    id: 3,
    title: 'Bote de Pelota: Estrategias y Técnicas',
    filename: 'botepelota.mp4',
    description:
      'Explora las estrategias y técnicas esenciales para mejorar tu habilidad en el bote de pelota. Descubre cómo controlar el juego desde el fondo de la cancha con este tutorial detallado.',
  },
  {
    id: 4,
    title: 'Rutina de Peloteo para Consistencia',
    filename: 'peloteo.mp4',
    description:
      'Sigue esta rutina de peloteo diseñada para mejorar la consistencia en tu juego. Aprende a mantener un ritmo constante y minimizar errores no forzados.',
  },
  {
    id: 5,
    title: 'Desarrollo de Potencia en el Saque',
    filename: 'altura.mp4',
    description:
      'Descubre ejercicios efectivos para desarrollar la potencia en tu saque. Este video te proporcionará técnicas específicas y consejos para aumentar la velocidad y la precisión.',
  },
  {
    id: 6,
    title: 'Control del Bote de Pelota en Diferentes Superficies',
    filename: 'botepelota.mp4',
    description:
      'Adapta tu juego a diferentes superficies de la cancha. Aprende a controlar el bote de la pelota en arcilla, hierba y pista dura para mantenerte competitivo en cualquier entorno.',
  },
  // Agrega más objetos de video según sea necesario
]

const VideoGallery = () => {
  return (
    <div className="video-gallery-container">
      {videos.map((video) => (
        <div key={video.id} className="video-item">
          <h2 className="video-title">{video.title}</h2>
          <p className="video-description">{video.description}</p>
          <div className="video-wrapper">
            <ReactPlayer
              url={`/Videos/${video.filename}`}
              controls={true}
              width="100%"
              height="100%"
            />
          </div>
        </div>
      ))}
    </div>
  )
}

export default VideoGallery
