import styled from 'styled-components'

export const ContenedorPrincipal = styled.div`
  width: 100%;
  background-color: #0d1416; /* Fondo negro */
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ContenedorBotones = styled.div`
  width: 100%;
  background-color: #0d1416; /* Gris oscuro */
  display: flex;
  justify-content: space-around;
  position: relative;
  font-family: 'Lumier';
`

export const ContenidoVista = styled.div`
  flex-grow: 1;
  width: 100%;
  padding: 1rem;
  color: #fff;
`


export const Boton1 = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => (props.activa ? '#2a3c40' : '#182329')};
  color: #6699ff;
  border: none;
  padding: 0.8rem 1rem;
  font-size: 2vh;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s;
  width: 33.33%;
  @media (max-width: 767px) {
    font-size: 1.4vh;
  }
  &:hover {
    background-color: ${(props) => (props.activa ? '#3e5155' : '#3e5155')};
  }
`

export const Boton2 = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => (props.activa ? '#2a3c40' : '#182329')};
  color: #ffa500;
  border: none;
  padding: 0.5rem 1rem;
  font-size: 2vh;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s;
  width: 33.33%;

  @media (max-width: 767px) {
    font-size: 1.4vh;
  }

  &:hover {
    background-color: ${(props) => (props.activa ? '#3e5155' : '#3e5155')};
  }
`

export const Boton3 = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => (props.activa ? '#2a3c40' : '#182329')};
  color: #a0f000;
  border: none;
  padding: 0.5rem 1rem;
  font-size: 2vh;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s;
  width: 33.33%;
  @media (max-width: 767px) {
    font-size: 1.4vh;
  }
  &:hover {
    background-color: ${(props) => (props.activa ? '#3e5155' : '#3e5155')};
  }
`

export const BarraIluminada = styled.div`
  position: absolute;
  height: 6px;
  width: 33.33%;
  bottom: -6px;
  transition: left 0.3s;

  @media (max-width: 767px) {
    bottom: -3px;
    height: 4px;
  }
`

export const BarraIluminadaVerde = styled(BarraIluminada)`
  left: ${(props) => (props.ancho - 1) * 33.33}%;
  background: linear-gradient(
    to right,
    #a0f000,
    #7bb92d,
    #5ba614,
    #3f8b00,
    #2d6300,
    #004d00
  );
`

export const BarraIluminadaNaranja = styled(BarraIluminada)`
  left: ${(props) => (props.ancho - 1) * 33.33}%;
  background: linear-gradient(
    to right,
    #ffa500,
    #ffc940,
    #ffd773,
    #ffecb3,
    #ffe8a0,
    #ffd46f
  );
`

export const BarraIluminadaAzul = styled(BarraIluminada)`
  left: ${(props) => (props.ancho - 1) * 33.33}%;
  background: linear-gradient(
    to right,
    #6699ff,
    #6fa4ff,
    #82b9ff,
    #9eceff,
    #b9e2ff,
    #d4f7ff
  );
`

