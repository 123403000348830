import React, { useEffect } from 'react';
import Menu from '../../components/Menu/Navbar.jsx';
import Header from '../../components/Header/header.jsx';
import Logo from '../../components/Logo/logo.jsx';
import Footer from '../../components/Footer/footer.jsx';
import { ContainerCalendario } from './CalendarioStyle';
import $ from 'jquery';
import './evo-calendar.css';
import './evo-calendar.js';

const Calendario = () => {
  useEffect(() => {
    // Verificar si jQuery está disponible antes de intentar inicializar Evo Calendar
    if (typeof $ !== 'undefined') {
      // Configurar eventos para tu calendario
      const eventos = [



        {
          id: 1,
          name: 'NSTA Kids',
          date: ["03/03/2024"],     // FECHA UNICA
          type: 'Evento importante',
          description: "Este evento de carácter recreativo, esta dedicado a las edades comprendidas entre los 4 y los 12 años. El objetivo es adentrar a los más pequeños en la competición, que den sus primeros pasos en la misma, que aprendan las reglas de juego básicas, hagan nuevas amistades y, fundamentalmente, que se enamoren del tenis. Se disputan las siguientes categorías en formato mixto (masculinos y femeninos en conjunto):  Sub-6; sub-8; sub-10 y sub-12.",
          color: "#ff00c3",
          url: "/eventos/torneonstakids" 
        },


        {
          id: 2,
          name: 'NSTA Family',
          date: ["28/02/2024"],     // FECHA UNICA
          type: 'Evento importante',
          description: "Es uno de los últimos eventos diseñados por NSTA con el objetivo de disfrutar del tenis en familia. Consiste en una jornada de clases de tenis dirigidas a todo el núcleo familiar, donde comparten la misma clase mama, papa, hij@s, abuelos, tíos, primos, etc. Siempre el grupo familiar debe ser mayor de 3 y menos de 6 participantes.",
          color: "#ff7700",
          url: "/eventos/nstafamily" 
        },




        {
          id: 3,
          name: '7º circuito tenis de Huelva',
          date: ["01/03/2024", "03/03/2024"],    // DEL HASTA!!!  VARIAS FECHAS
          type: 'Evento importante',
          description: "El torneo federado se celebrará del viernes 1 al domingo 3 de marzo, como parte del 7º circuito de tenis de Huelva.",
          color: "#00ff33",
          url: "/eventos/7circuito" 
        },


        {
          id: 4,
          name: 'Campeonato de Andalucía Alevín y Cadete Fase Provincial Huelva',
          date: ["07/03/2024", "10/03/2024"],  
          type: 'Evento importante',
          description: "La fase provincial del Campeonato de Andalucía, en las categorías Alevín y Cadete, se celebrará en el Bellavista Huelva Club. Este evento deportivo reunirá a los jóvenes talentos del tenis, quienes competirán por avanzar a la siguiente etapa del campeonato regional. Las instalaciones del Bellavista Huelva Club ofrecen un entorno ideal para la competición, prometiendo ser un escenario emocionante tanto para los participantes como para los espectadores.",
          color: "#008cff",
          url: "/eventos/campeonatoalevincadete" 
        },



        {
          id: 5,
          name: 'Intensivo Competición Semana Santa',
          date: ["25/03/2024", "27/03/2024"],  
          type: 'Evento importante',
          description: "Intensivo Competición Semana Santa los dias 25, 26 y 27 de Marzo en Club Rio Grande.",
          color: "#ff1c1c",
          url: "/eventos/intensivosemanasanta" 
        },


        {
          id: 6,
          name: 'Torneo de Pádel',
          date: ["27/04/2024"] ,    
          type: 'Evento importante',
          description: "Torneo de Pádel Palomares Pádel Club y Bellavista Huelva Club  ida 27/04/2024.",
          color: "#00f2ff",
          url: "/eventos/torneopadelpalomaresybellavista" 
        },

        {
          id: 6,
          name: 'Torneo de Pádel',
          date: ["08/06/2024"] ,    
          type: 'Evento importante',
          description: "Torneo de Pádel Palomares Pádel Club y Bellavista Huelva Club vuelta 08/06/2024.",
          color: "#00f2ff",
          url: "/eventos/torneopadelpalomaresybellavista" 
        },


        
        {
          id: 7,
          name: 'Campus Semana Santa',
          date: ["25/03/2024", "27/03/2024"],  
          type: 'Evento importante',
          description: "Campus Semana Santa en G.E Airbus",
          color: "#aa00ff",
          url: "/eventos/campusairbus" 
        },





        {
          id: 8,
          name: 'Torneo padel solidario',
          date: ["04/05/2024"],  
          type: 'Evento importante',
          description: "Torneo de padel solidario por la Mucopolisacaridosis en Palomares Padel Club",
          color: "#aa00ff",
          url: "/eventos/mucopolisacaridosis" 
        },




        
        {
          id: 9,
          name: 'III Torneo NSTA Kids',
          date: ["28/04/2024"],  
          type: 'Evento importante',
          description: "Este evento de carácter recreativo, esta dedicado a las edades comprendidas entre los 4 y los 12 años. El objetivo es adentrar a los más pequeños en la competición, que den sus primeros pasos en la misma, que aprendan las reglas de juego básicas, hagan nuevas amistades y fundamentalmente que se enamoren del tenis.",
          color: "#ff00c3",
          url: "/eventos/3torneonstakids" 
        },



    
        {
          id: 10,
          name: 'Circuito TWE en Río Grande',
          date: ["26/04/2024", "28/04/2024"],  
          type: 'Evento importante',
          description: "Continuamos con el 2º Circuito Tennis Warehouse Europe con la celebración del 3er torneo en las magníficas instalaciones de la Asociación Recreativa Río Grande.",
          color: "#547005",
          url: "/eventos/circuitotw" 
        },




        {
          id: 11,
          name: 'Torneo Dobles NSTA',
          date: ["01/05/2024"],  
          type: 'Evento importante',
          description: "Torneo Dobles NSTA en Club Rio Grande.",
          color: "#ff0000",

          url: "/eventos/torneodobles" 
        },



        {
          id: 12,
          name: 'Torneo Nocturno',
          date: ["28/06/2024"],  
          type: 'Evento importante',
          description: "Torneo Nocturno Viernes 28 de Junio.",
          color: "#1e00ff9b",

          url: "/eventos/torneonocturno28junio" 
        },






        {
          id: 13,
          name: 'Torneo Benéfico para la investigación de la Ataxia Telangiectasia',
          date: ["29/06/2024", "30/06/2024"],   
          type: 'Evento importante',
          description: "Torneo Benéfico para la investigación de la Ataxia Telangiectasia 29 y 30 de Junio",
          color: "#ff009d9b",

          url: "/eventos/torneotelangiectasia" 
        },


        {
          id: 14,
          name: 'Torneo Federado Bellavista Huelva Club',
          date: ["05/07/2024", "07/07/2024"],      
          type: 'Evento importante',
          description: "Torneo Federado en Bellavista Huelva Club del 5 de Julio al 7 de Julio 2024.",
          color: "#0048ff9b",

          url: "/eventos/TorneoFederadoBellavista" 
        },



        {
          id: 15,
          name: 'Torneo de Verano Bellavista Huelva Club',
          date: ["12/07/2024", "14/07/2024"],      
          type: 'Evento importante',
          description: "Torneo de verano los días 12, 13 y 14 de Julio en Bellavista Huelva Club.",
          color: "#0dff009b",

          url: "/eventos/torneoveranobhc" 
        },


        {
          id: 16,
          name: 'Spartan Tenis Tour 2024',
          date: ["19/08/2024", "25/08/2024"],      
          type: 'Evento importante',
          description: "Spartan Tenis Tour 2024 del 19 de Agosto al 25 de Agosto.",
          color: "#ff00009b",

          url: "/eventos/spartan2024" 
        },


        {
          id: 17,
          name: 'Spartan Adultos Tenis Tour 2024.',
          date: ["19/08/2024", "25/08/2024"],      
          type: 'Evento importante',
          description: "Spartan Adultos Tenis Tour 2024 del 19 de Agosto al 25 de Agosto. Categorías Absoluto y +35.",
          color: "#0011ff9b",

          url: "/eventos/spartanabsoluto2024" 
        },


        // {
        //   id: 18,
        //   name: 'Torneo Social Copa Guadalquivir Río Grande 2024',
        //   date: ["30/09/2024", "05/10/2024"],      
        //   type: 'Evento importante',
        //   description: "Torneo Social Club Rio Grande Copa Guadalquivir, Categorías: Absoluto Masculino y Femenino Veterano + 45 Masculino",
        //   color: "#1482ff9b",

        //   url: "/eventos/TorneoGuadalquivir" 
        // },


   
       





      ];




      // Convertir las fechas de 'dd/mm/yyyy' a 'mm/dd/yyyy'
      const eventosConvertidos = eventos.map(evento => {
        if (Array.isArray(evento.date)) {
          return {
            ...evento,
            date: evento.date.map(fecha => convertirFecha(fecha))
          };
        } else {
          return {
            ...evento,
            date: convertirFecha(evento.date)
          };
        }
      });

      // Inicializar Evo Calendar con eventos convertidos
      $("#calendar").evoCalendar({
        language: 'es', // Asegura que el idioma español esté activado
        calendarEvents: eventosConvertidos,
      });



      
    } else {
      console.error("jQuery no está cargado. Asegúrate de que jQuery se cargue antes de Evo Calendar.");
    }
  }, []);

  return (
    <div>
      <Menu />
      <Header />
      <Logo />
      <ContainerCalendario>
        <div id="calendar"></div>
      </ContainerCalendario>
      <Footer />
    </div>
  );
};

// Función para convertir las fechas de 'dd/mm/yyyy' a 'mm/dd/yyyy'
function convertirFecha(fecha) {
  const partes = fecha.split('/');
  return `${partes[1]}/${partes[0]}/${partes[2]}`;
}

export default Calendario;