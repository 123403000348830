import React from 'react';
import styled from 'styled-components';
import Menu from '../../../components/Menu/Navbar'
import Header from '../../../components/Header/header'
import Logo from '../../../components/Logo/logo'
import Footer from '../../../components/Footer/footer'
import FxView from '../../../components/FxView/fxview'




const StyledImage = styled.img`
  width: 100%; // Asegura que la imagen sea responsive
  max-width: 100%;
  margin: 50px auto; // Centra la imagen horizontalmente
  display: block; // Evita que la imagen sea inline
  border: 2px solid #e1e1e1; // Un borde sólido y sutil
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); // Sombra suave para crear profundidad
  border-radius: 5px; // Opcional: bordes redondeados para suavizar los bordes
  filter:  contrast(120%) saturate(70%); // Ajustes de brillo, contraste y saturación

  
`;


const ContainerGeneral = styled.div`
  margin: 140px auto; 
  width: 75%; 
  padding: 20px;
  min-height: 500px;

  // Esta media query se activa cuando el ancho de la ventana es menor a 768px,
  // que generalmente corresponde a dispositivos móviles
  @media (max-width: 768px) {
    width: 100%; // Ancho del 95% en dispositivos móviles
  }
`;

const BotonInscripcion = styled.button`
  font-family: 'GarnetLight';
  margin: 5px; /* Ajusta los márgenes para que se vean bien cuando se apilen */
  background-color: #a0f000cf;
  color: #000000;
  border: black solid 1px;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 13px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, transform 0.2s ease;
  text-decoration: none;

  &:hover {
    color: white;
    background-color: #000000; /* Un tono más oscuro para el efecto hover */
    transform: scale(1.05);
  }

  @media (max-width: 768px) {
    padding: 5px 10px;
    font-size: 12px;
    margin: 5px ;
  }
`;

const BotonDescargarCalendario = styled(BotonInscripcion)` 
`;

const BotonCentrado = styled.div`
  display: flex;
  justify-content: center; // Centra el contenido horizontalmente
  margin: 20px 0; // Añade un margen superior e inferior para separarlo de otros elementos
`;


const TituloGrande = styled.h1`
  font-family: 'GarnetLight';
  font-weight: bold;
  margin: 10px 0;
  margin-top: 50px ;
  text-align: left;
  color: #a0f000;
  font-size: 28px;
`;


const EscuelaDeFormacion = () => {
  // Función para manejar la descarga de la imagen del calendario
  const descargarCalendario = () => {
    const link = document.createElement('a');
    link.href = `${process.env.PUBLIC_URL}/Calendario NSTA 23-24.jpg`; // La ruta de tu imagen
    link.download = 'Calendario NSTA 23-24.jpg'; // El nombre bajo el cual se guardará la imagen
    document.body.appendChild(link); // Se agrega el enlace temporalmente al documento
    link.click(); // Simula un clic en el enlace para iniciar la descarga
    document.body.removeChild(link); // Se elimina el enlace del documento
  };

  return (
    <div>
      <Menu />
      <Header />
      <Logo />
      <FxView />
      <ContainerGeneral>

        <TituloGrande>Calendario 2023-2024</TituloGrande>

        <StyledImage src={`${process.env.PUBLIC_URL}/Calendario NSTA 23-24.jpg`} alt="Calendario NSTA 23-24" />
        <BotonCentrado>
          <BotonDescargarCalendario onClick={descargarCalendario}>
            Descargar Calendario
          </BotonDescargarCalendario>
        </BotonCentrado>
      </ContainerGeneral>
      <Footer />
    </div>
  );
};

export default EscuelaDeFormacion;