import React from 'react'
import styled from 'styled-components'
import Menu from '../../../components/Menu/Navbar'
import Header from '../../../components/Header/header'
import Logo from '../../../components/Logo/logo'
import Footer from '../../../components/Footer/footer'
import { ContainerGeneral, TituloGrande, ContainerTexto } from '../styletexto'
import FxView from '../../../components/FxView/fxview'
import FxImagenBackGround from '../../../components/FxImagenBackGround/fximagenbackground'

import { faSun } from '@fortawesome/free-solid-svg-icons'

const ContainerImagenes = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`

const Imagen = styled.img`
  width: 90%;
  max-width: 900px;
  height: auto;
  margin: 10px;
`

const IntensivoDeNavidad = () => {
  return (
    <div>
      <Menu />
      <Header />
      <Logo />
      <FxView />

      <FxImagenBackGround icon={faSun} />

      <ContainerGeneral>
        <ContainerTexto>
          <TituloGrande>Tarifa Rio Grande Temporada 24 - 25 </TituloGrande>

          <ContainerImagenes>
            <Imagen
              src={require('./TarifaRioGrande.jpg')}
              alt="Tarifa Rio Grande 2024 - 2025"
            />
          </ContainerImagenes>
        </ContainerTexto>
      </ContainerGeneral>

      <Footer />
      <></>
    </div>
  )
}

export default IntensivoDeNavidad
