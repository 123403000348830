import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import NotFoundPage from '../../components/NotFoundPage/notfoundpage.jsx'; 
import { useEffect } from 'react';

import Academy from '../../views/Academy/academy.jsx'
import Eventos from '../../views/Eventos/eventos.jsx'
import Calendario from '../../views/Calendario/calendario.jsx'
import Team from '../../views/Team/team.jsx'
import SociosNstaLogin from '../../views/SociosClubsNsta/registrarse.jsx'
import SociosNsa from '../../views/SociosClubsNsta/sociosnsa.jsx'
import RecuperacionDeContraseña from '../../views/SociosClubsNsta/recuperaciondecontraseña.jsx'
import Contacto from '../../views/Contacto/contacto.jsx'
import Noticias from '../../views/Noticias/noticias.jsx'
import EnlacesDeInteres from '../../views/EnlaceInteres/enlacesdeinteres.jsx'

import VideoEdicion from '../../views/VideoEdicion/videoedicion.jsx'



//RIO GRANDE
import RioGrande from '../../views/RioGrande/riogrande.jsx'

import RioCalendarioRioGrande from '../../views/RioGrande/1EscuelaDeTenis/calendarioriogrande.jsx'

import TarifaRioGrande from '../../views/RioGrande/1EscuelaDeTenis/tarifariogrande.jsx'
import InformacionEInscripcionRG from '../../views/RioGrande/1EscuelaDeTenis/informacioneinscripcionrg.jsx'

import RioEscuelaDeFormación from '../../views/RioGrande/1EscuelaDeTenis/escueladeformacion.jsx'
import RioEscuelaDeAdultos from '../../views/RioGrande/1EscuelaDeTenis/escueladeadultos.jsx'
import RioClasesParticulares from '../../views/RioGrande/1EscuelaDeTenis/clasesparticulares.jsx'
import RioPlanificacionDeEntrenamiento from '../../views/RioGrande/1EscuelaDeTenis/planificaciondeentrenamiento.jsx'

import RioAcademiaNSTADeCompeticion from '../../views/RioGrande/2AcademiaDeCompeticion/academianstadecompeticion.jsx'
import RioPlanificacionDeEntrenamientoCompeticion from '../../views/RioGrande/2AcademiaDeCompeticion/planificaciondeentrenamientocompeticion.jsx'
import RioProgramaNSTATour from '../../views/RioGrande/2AcademiaDeCompeticion/programanstatour.jsx'
import RioServiciosDeCoachATorneos from '../../views/RioGrande/2AcademiaDeCompeticion/serviciosdecoachatorneos.jsx'

import RioIntensivosDeVerano from '../../views/RioGrande/3IntensivosTenis/intensivodeverano.jsx'
import RioOtrosIntensivos from '../../views/RioGrande/3IntensivosTenis/otrosintensivos.jsx'

import RioCircuitoInterno from '../../views/RioGrande/4eventos/circuitointerno.jsx'

import RioCalendarioDeCompeticion from '../../views/RioGrande/5otrosservicios/calendariodecompeticion.jsx'
import RioPlanificacionDeTodosLosNiveles from '../../views/RioGrande/5otrosservicios/planificaciondetodoslosniveles.jsx'
import RioPlanificacionIndividualizada from '../../views/RioGrande/5otrosservicios/planificacionindividualizada.jsx'
import RioEstadisticasYVideoAnalisis from '../../views/RioGrande/5otrosservicios/estadisticasyvideoanalisis.jsx'
import RioVideoTutoriales from '../../views/RioGrande/5otrosservicios/videotutoriales.jsx'
import RioPreparacionFisica from '../../views/RioGrande/5otrosservicios/preparacionfisica.jsx'
import RioMentalCoach from '../../views/RioGrande/5otrosservicios/mentalcoach.jsx'
import RioserviciosDeEncordados from '../../views/RioGrande/5otrosservicios/serviciosdeencordados.jsx'

import GlobalCollage from '../../views/RioGrande/8GlobalCollage/globalcollage.jsx'

import RioGrandeInstalaciones from '../../views/RioGrande/7Instalaciones/instalaciones.jsx'

import Patrocinadores from '../../views/RioGrande/6patrocinadores/patrocinadores.jsx'




//BELLAVISTA
import Bellavista from '../../views/Bellavista/bellavista.jsx'


import InformacionEInscripcionBHC from '../../views/Bellavista/1EscuelaDeTenis/informacioneinscripcionbhc.jsx'

import BellavistaEscuelaDeFormación from '../../views/Bellavista/1EscuelaDeTenis/escueladeformacion.jsx'
import BellavistaEscuelaDeAdultos from '../../views/Bellavista/1EscuelaDeTenis/escueladeadultos.jsx'
import BellavistaClasesParticulares from '../../views/Bellavista/1EscuelaDeTenis/clasesparticulares.jsx'
import BellavistaPlanificacionDeEntrenamiento from '../../views/Bellavista/1EscuelaDeTenis/planificaciondeentrenamiento.jsx'

import BellavistaAcademiaNSTADeCompeticion from '../../views/Bellavista/2AcademiaDeCompeticion/academianstadecompeticion.jsx'
import BellavistaPlanificacionDeEntrenamientoCompeticion from '../../views/Bellavista/2AcademiaDeCompeticion/planificaciondeentrenamientocompeticion.jsx'
import BellavistaProgramaNSTATour from '../../views/Bellavista/2AcademiaDeCompeticion/programanstatour.jsx'
import BellavistaServiciosDeCoachATorneos from '../../views/Bellavista/2AcademiaDeCompeticion/serviciosdecoachatorneos.jsx'

import BellavistaIntensivosDeVerano from '../../views/Bellavista/3IntensivosTenis/intensivodeverano.jsx'

import BellavistaEventoTenis from '../../views/Bellavista/4eventos/eventotenisbhc.jsx'


import BellavistaCalendarioDeCompeticion from '../../views/Bellavista/5otrosservicios/calendariodecompeticion.jsx'
import BellavistaPlanificacionDeTodosLosNiveles from '../../views/Bellavista/5otrosservicios/planificaciondetodoslosniveles.jsx'
import BellavistaPlanificacionIndividualizada from '../../views/Bellavista/5otrosservicios/planificacionindividualizada.jsx'
import BellavistaoEstadisticasYVideoAnalisis from '../../views/Bellavista/5otrosservicios/estadisticasyvideoanalisis.jsx'
import BellavistaoVideoTutoriales from '../../views/Bellavista/5otrosservicios/videotutoriales.jsx'
import BellavistaPreparacionFisica from '../../views/Bellavista/5otrosservicios/preparacionfisica.jsx'
import BellavistaserviciosDeEncordados from '../../views/Bellavista/5otrosservicios/serviciosdeencordados.jsx'
import BellavistaCumpleaños from '../../views/Bellavista/5otrosservicios/cumpleaños.jsx'


import BellavistaCampusDeVerano from '../../views/Bellavista/Actividades/campusdeverano.jsx'
import BellavistaAlquilerDePistas from '../../views/Bellavista/Actividades/alquilerdepistas.jsx'
import BellavistaKioskoBar from '../../views/Bellavista/Actividades/kioskobar.jsx'

import BellavistaEscuelaPadelBHC from '../../views/Bellavista/Padel/escueladepadelbhc.jsx'
import BellavistaEventosPadelBHC from '../../views/Bellavista/Padel/eventospadelbhc.jsx'
import BellavistaIntensivoPadel from '../../views/Bellavista/Padel/intensivospadel.jsx'
import BellavistaOtrosServicios from '../../views/Bellavista/Padel/otrosservicios.jsx'

import BellavistaInstalaciones from '../../views/Bellavista/6Instalaciones/instalaciones.jsx'

//PALOMARES
import Palomares from '../../views/Palomares/palomares.jsx'

import PalomaresEventos from '../../views/Palomares/1Eventos/eventos.jsx'
import PalomaresEscuelaDePadel from '../../views/Palomares/2EscuelaDePadel/escueladepadel.jsx'
import PalomaresAlquilerDePistas from '../../views/Palomares/3AlquilerDePistas/alquilerdepistas.jsx'
import PalomaresKioskoBar from '../../views/Palomares/4KioskoBar/kioskobar.jsx'
import PalomaresInstalaciones from '../../views/Palomares/5Instalaciones/instalaciones.jsx'


//AIRBUS
import Airbus from '../../views/Airbus/airbus.jsx'

import AirbusEventos from '../../views/Airbus/1Eventos/eventos.jsx'
import AirbusEscuelaDePadel from '../../views/Airbus/2EscuelaDePadel/escueladepadel.jsx'
import AirbusCampusDeVerano from '../../views/Airbus/3CampusDeVerano/campusdeverano.jsx'
import AirbusAlquilerDePistas from '../../views/Airbus/4AlquilerDePistas/alquilerdepistas.jsx'
import AirbusKioskoBar from '../../views/Airbus/5KioskoBar/kioskobar.jsx'
import AirbusInstalaciones from '../../views/Airbus/6Instalaciones/instalaciones.jsx'




//EVENTS
import TroneoNSTAKids from '../../views/Eventos/torneonstakids.jsx'
import NSTAFamily from '../../views/Eventos/nstafamily.jsx'
import SeptimoCircuito from '../../views/Eventos/septimocircuito.jsx'
import CampeonatoAlevinCadete from '../../views/Eventos/campeonatoalevincadete.jsx'
import IntensivoSemanaSanta from '../../views/Eventos/intensivosemanasanta.jsx'
import TorneoPadelPalomaresYBellavista from '../../views/Eventos/torneopadelpalomaresybellavista.jsx'
import CampusSemanaSanta from '../../views/Eventos/campusairbus.jsx'
import Mucopolisacaridosis from '../../views/Eventos/mucopolisacaridosis.jsx'
import TercerTorneoNstaKids from '../../views/Eventos/3torneonstakids.jsx'
import CircuitoTW from '../../views/Eventos/circuitotw.jsx'
import TorneoDobles from '../../views/Eventos/torneodobles.jsx'
import TorneoNocturno28Junio from '../../views/Eventos/TorneoNocturno28Junio.jsx'
import TorneoTelangiectasia from '../../views/Eventos/TorneoTelangiectasia.jsx'
import TorneoFederadoBellavista from '../../views/Eventos/TorneoFederadoBellavista.jsx'
import TorneoVeranoBhc from '../../views/Eventos/torneoveranobhc.jsx'
import Spartan2024 from '../../views/Eventos/spartan2024.jsx'
import SpartanAbsoluto2024 from '../../views/Eventos/spartanabsoluto2024.jsx'
// import TorneoGuadalquivir from '../../views/Eventos/TorneoGuadalquivir.jsx'



function ScrollToTop() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return null;
}

const Router = () => {



  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>

        <Route path="/" element={<Academy />} />
        <Route path="eventos" element={<Eventos />} />
        <Route path="calendario" element={<Calendario />} />
        <Route path="team" element={<Team />} />
        <Route path="sociosnstalogin" element={<SociosNstaLogin />} />
        <Route path="contacto" element={<Contacto />} />
        <Route path="noticias" element={<Noticias />} />
        <Route path="sociosnsa" element={<SociosNsa />} />
        <Route path="recuperacion" element={<RecuperacionDeContraseña />} />
        <Route path="enlacesdeinteres" element={<EnlacesDeInteres />} />
        <Route path="videoedicion" element={<VideoEdicion />} />




        <Route path="/clubs/riogrande" element={<RioGrande />} />

        <Route path="/clubs/riogrande/calendarioriogrande" element={<RioCalendarioRioGrande />} />

        <Route path="/clubs/riogrande/tarifariogrande" element={<TarifaRioGrande />} />

        <Route path="/clubs/riogrande/informacioneinscripcionrg" element={<InformacionEInscripcionRG />} />
        <Route path="/clubs/riogrande/escueladeformación" element={<RioEscuelaDeFormación />} />
        <Route path="/clubs/riogrande/escueladeadultos" element={<RioEscuelaDeAdultos />} />
        <Route path="/clubs/riogrande/clasesparticulares" element={<RioClasesParticulares />} />
        <Route path="/clubs/riogrande/planificaciondeentrenamiento" element={<RioPlanificacionDeEntrenamiento />} />

        <Route path="/clubs/riogrande/academianstadecompeticion" element={<RioAcademiaNSTADeCompeticion />} />
        <Route path="/clubs/riogrande/planificaciondeentrenamientocompeticion" element={<RioPlanificacionDeEntrenamientoCompeticion />} />
        <Route path="/clubs/riogrande/programanstatour" element={<RioProgramaNSTATour />} />
        <Route path="/clubs/riogrande/serviciosdecoachatorneos" element={<RioServiciosDeCoachATorneos />} />

        <Route path="/clubs/riogrande/intensivosdeverano" element={<RioIntensivosDeVerano />} />
        <Route path="/clubs/riogrande/otrosintensivos" element={<RioOtrosIntensivos />} />

        <Route path="/clubs/riogrande/circuitointernonsta" element={<RioCircuitoInterno />} />

        <Route path="/clubs/riogrande/calendariodecompeticion" element={<RioCalendarioDeCompeticion />} />
        <Route path="/clubs/riogrande/planificaciondetodoslosniveles" element={<RioPlanificacionDeTodosLosNiveles />} />
        <Route path="/clubs/riogrande/planificacionindividualizada" element={<RioPlanificacionIndividualizada />} />
        <Route path="/clubs/riogrande/estadisticasyvideoanalisis" element={<RioEstadisticasYVideoAnalisis />} />
        <Route path="/clubs/riogrande/videotutoriales" element={<RioVideoTutoriales />} />
        <Route path="/clubs/riogrande/preparacionfisica" element={<RioPreparacionFisica />} />
        <Route path="/clubs/riogrande/mentalcoach" element={<RioMentalCoach />} />
        <Route path="/clubs/riogrande/serviciosdeencordados" element={<RioserviciosDeEncordados />} />

        <Route path="/clubs/globalcollage" element={<GlobalCollage />} />

        <Route path="/clubs/riogrande/instalaciones" element={<RioGrandeInstalaciones />} />

        <Route path="/clubs/patrocinadores" element={<Patrocinadores />} />



    



        <Route path="/clubs/bellavista" element={<Bellavista />} />

        <Route path="/clubs/bellavista/informacioneinscripcionbhc" element={<InformacionEInscripcionBHC />} />
        <Route path="/clubs/bellavista/escueladeformación" element={<BellavistaEscuelaDeFormación />} />
        <Route path="/clubs/bellavista/escueladeadultos" element={<BellavistaEscuelaDeAdultos />} />
        <Route path="/clubs/bellavista/clasesparticulares" element={<BellavistaClasesParticulares />} />
        <Route path="/clubs/bellavista/planificaciondeentrenamiento" element={<BellavistaPlanificacionDeEntrenamiento />} />

        <Route path="/clubs/bellavista/academianstadecompeticion" element={<BellavistaAcademiaNSTADeCompeticion />} />
        <Route path="/clubs/bellavista/planificaciondeentrenamientocompeticion" element={<BellavistaPlanificacionDeEntrenamientoCompeticion />} />
        <Route path="/clubs/bellavista/programanstatour" element={<BellavistaProgramaNSTATour />} />
        <Route path="/clubs/bellavista/serviciosdecoachatorneos" element={<BellavistaServiciosDeCoachATorneos />} />

        <Route path="/clubs/bellavista/intensivosdeverano" element={<BellavistaIntensivosDeVerano />} />

        <Route path="/clubs/bellavista/eventotenisbhc" element={<BellavistaEventoTenis />} />


        <Route path="/clubs/bellavista/calendariodecompeticion" element={<BellavistaCalendarioDeCompeticion />} />
        <Route path="/clubs/bellavista/planificaciondetodoslosniveles" element={<BellavistaPlanificacionDeTodosLosNiveles />} />
        <Route path="/clubs/bellavista/planificacionindividualizada" element={<BellavistaPlanificacionIndividualizada />} />
        <Route path="/clubs/bellavista/estadisticasyvideoanalisis" element={<BellavistaoEstadisticasYVideoAnalisis />} />
        <Route path="/clubs/bellavista/videotutoriales" element={<BellavistaoVideoTutoriales />} />
        <Route path="/clubs/bellavista/preparacionfisica" element={<BellavistaPreparacionFisica />} />
        <Route path="/clubs/bellavista/serviciosdeencordados" element={<BellavistaserviciosDeEncordados />} />
        <Route path="/clubs/bellavista/cumnpleañosdeportivos" element={<BellavistaCumpleaños />} />


        <Route path="/clubs/bellavista/escueladepadelbhc" element={<BellavistaEscuelaPadelBHC />} />
        <Route path="/clubs/bellavista/eventospadelbhcs" element={<BellavistaEventosPadelBHC />} />
        <Route path="/clubs/bellavista/intensivospadel" element={<BellavistaIntensivoPadel />} />
        <Route path="/clubs/bellavista/otrosservicios" element={<BellavistaOtrosServicios />} />


        <Route path="/clubs/bellavista/campusdeverano" element={<BellavistaCampusDeVerano />} />
        <Route path="/clubs/bellavista/alquilerdepistas" element={<BellavistaAlquilerDePistas />} />
        <Route path="/clubs/bellavista/kioskobar" element={<BellavistaKioskoBar />} />

        <Route path="/clubs/bellavista/instalaciones" element={<BellavistaInstalaciones />} />




        <Route path="/clubs/palomares" element={<Palomares />} />

        <Route path="/clubs/palomares/eventos" element={<PalomaresEventos />} />
        <Route path="/clubs/palomares/escueladepadel" element={<PalomaresEscuelaDePadel />} />
        <Route path="/clubs/palomares/alquilerdepistas" element={<PalomaresAlquilerDePistas />} />
        <Route path="/clubs/palomares/kioskobar" element={<PalomaresKioskoBar />} />
        <Route path="/clubs/palomares/instalaciones" element={<PalomaresInstalaciones />} />






        <Route path="/clubs/airbus" element={<Airbus />} />

        <Route path="/clubs/airbus/eventos" element={<AirbusEventos />} />
        <Route path="/clubs/airbus/escueladepadel" element={<AirbusEscuelaDePadel />} />
        <Route path="/clubs/airbus/campusdeverano" element={<AirbusCampusDeVerano />} />
        <Route path="/clubs/airbus/alquilerdepistas" element={<AirbusAlquilerDePistas />} />
        <Route path="/clubs/airbus/kioskobar" element={<AirbusKioskoBar />} />
        <Route path="/clubs/airbus/instalaciones" element={<AirbusInstalaciones />} />






        <Route path="/eventos/torneonstakids" element={<TroneoNSTAKids />} />
        <Route path="/eventos/nstafamily" element={<NSTAFamily />} />
        <Route path="/eventos/7circuito" element={<SeptimoCircuito />} />
        <Route path="/eventos/campeonatoalevincadete" element={<CampeonatoAlevinCadete />} />
        <Route path="/eventos/intensivosemanasanta" element={<IntensivoSemanaSanta />} />
        <Route path="/eventos/torneopadelpalomaresybellavista" element={<TorneoPadelPalomaresYBellavista />} />
        <Route path="/eventos/campusairbus" element={<CampusSemanaSanta />} />
        <Route path="/eventos/mucopolisacaridosis" element={<Mucopolisacaridosis />} />
        <Route path="/eventos/3torneonstakids" element={<TercerTorneoNstaKids />} />
        <Route path="/eventos/circuitotw" element={<CircuitoTW />} />
        <Route path="/eventos/torneodobles" element={<TorneoDobles />} />
        <Route path="/eventos/torneonocturno28junio" element={<TorneoNocturno28Junio />} />
        <Route path="/eventos/torneotelangiectasia" element={<TorneoTelangiectasia />} />
        <Route path="/eventos/TorneoFederadoBellavista" element={<TorneoFederadoBellavista />} />
        <Route path="/eventos/torneoveranobhc" element={<TorneoVeranoBhc />} />
        <Route path="/eventos/spartan2024" element={<Spartan2024 />} />
        <Route path="/eventos/spartanabsoluto2024" element={<SpartanAbsoluto2024 />} />
        {/* <Route path="/eventos/TorneoGuadalquivir" element={<TorneoGuadalquivir />} /> */}


        <Route path="*" element={<NotFoundPage />} />

      </Routes>
    </BrowserRouter>
  );
};

export default Router;