import React from 'react';
import styled from 'styled-components';
import Menu from '../../../components/Menu/Navbar'
import Header from '../../../components/Header/header'
import Logo from '../../../components/Logo/logo'
import Footer from '../../../components/Footer/footer'
import {ContainerGeneral, Lista, Titulo, TituloChico, ContainerTexto, ElementoLista} from '../styletexto';
import FxView from '../../../components/FxView/fxview'
import FxImagenBackGround from '../../../components/FxImagenBackGround/fximagenbackground'

import { faCalendar } from '@fortawesome/free-solid-svg-icons';   


const ContainerImagenes = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px; /* Espacio entre imágenes */
`;
const Imagen = styled.img`
  width: 90%; /* Ajusta este valor según necesites */
  max-width: 900px; /* Ajusta este valor para controlar el máximo ancho de las imágenes */
  height: auto; /* Para mantener la proporción de las imágenes */
  margin: 10px;
`;






const CircuitoInterno = () => {
  return (
    <div>
      <Menu />
      <Header />
      <Logo />
      <FxView/>

      <FxImagenBackGround icon={faCalendar}/> {/* Usar el nuevo icono aquí */}






      <ContainerGeneral>
      <ContainerTexto>


        
      <Titulo> CALENDARIO DE COMPETICIÓN:</Titulo>

    
      <TituloChico><strong> Diseñamos calendarios de competiciones generales y específicas.  </strong></TituloChico>





      <Lista>
            <ElementoLista>Los calendarios generales hacen referencia a la serie de torneos federados de la provincia de Sevilla, como así también torneos relevantes a nivel autonómico. Por otro lado, también diseñamos un calendario de torneos de mayor relevancia a nivel nacional e internacional.
            </ElementoLista>
          </Lista>







          <ContainerImagenes>
            <Imagen src={require('./CALENDARIO1.jpg')} alt="CALENDARIO1" />
          </ContainerImagenes>




          <ContainerImagenes>
            <Imagen src={require('./CALENDARIO2.jpg')} alt="CALENDARIO2" />
          </ContainerImagenes>





          <ContainerImagenes>
            <Imagen src={require('./CALENDARIO3.jpg')} alt="CALENDARIO3" />
          </ContainerImagenes>








        </ContainerTexto>
      </ContainerGeneral>

      <Footer />
      <></>
    </div>
  )
}

export default CircuitoInterno
